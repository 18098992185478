import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c614f596"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-video" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src", "poster"]
const _hoisted_4 = ["src", "poster"]
const _hoisted_5 = {
  key: 3,
  class: "progress"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["message-video-box", [!_ctx.data.progress && _ctx.data.message.status === 'success' && _ctx.isH5 && 'message-video-cover']]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args))),
      ref: "skeleton"
    }, [
      ((_ctx.data.progress && _ctx.poster) || (_ctx.isH5 && _ctx.poster))
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["message-img", [_ctx.isWidth ? 'isWidth' : 'isHeight']]),
            src: _ctx.poster
          }, null, 10, _hoisted_2))
        : (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("video", {
              key: 1,
              class: "message-img video-h5-uploading",
              src: _ctx.data.url + '#t=0.1',
              poster: _ctx.data.url,
              preload: "auto",
              muted: "",
              ref: "video"
            }, null, 8, _hoisted_3))
          : (!_ctx.data.progress && !_ctx.isH5)
            ? (_openBlock(), _createElementBlock("video", {
                key: 2,
                class: "message-img video-web",
                src: _ctx.data.url,
                controls: "",
                preload: "metadata",
                poster: _ctx.poster,
                ref: "video"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
      (_ctx.data.progress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("progress", {
              value: _ctx.data.progress,
              max: "1"
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.show && _ctx.isH5)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dialog-video",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["self"]))
        }, [
          _createElementVNode("header", null, [
            _createElementVNode("i", {
              class: "icon icon-close",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["stop"]))
            })
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["dialog-video-box", [_ctx.isH5 ? 'dialog-video-h5' : '']]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["self"]))
          }, [
            _createElementVNode("video", {
              class: _normalizeClass([_ctx.isWidth ? 'isWidth' : 'isHeight']),
              src: _ctx.data.url,
              controls: "",
              autoplay: ""
            }, null, 10, _hoisted_7)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}