
import { defineComponent, reactive, watchEffect, toRefs } from 'vue';

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      open: false,
    });

    watchEffect(() => {
      data.open = props.open;
    });


    const toggle = () => {
      data.open = !data.open;
      ctx.emit('change', data.open);
    };

    return {
      ...toRefs(data),
      toggle,
    };
  },
});
