
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      message: {},
      isEdit: false,
    });

    watchEffect(() => {
      data.message = props.data;
      data.isEdit = props.isEdit;
    });

    const edit = () => {
      ctx.emit('edit', data.message);
    };

    return {
      ...toRefs(data),
      edit,
    };
  },
});
