
import TIM from '../../../../TUICore/tim';
import { reactive, toRefs, watch, watchEffect, defineComponent } from 'vue';
import manage from './manage.vue';

const memberProfile = defineComponent({
  props: {
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const TUIServer = manage?.TUIServer;
    const data = reactive({
      isFriendShip: false,
      userInfo: {},
      self: {},
    });
    watchEffect(() => {
      data.self = props.self;
    });
    watch(
      () => props.userInfo,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        const res = await TUIServer.getUserProfile([props?.userInfo?.userID]);
        data.userInfo = res?.data[0];
        checkFriend();
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const enter = async (ID: any, type: string) => {
      const name = `${type}${ID}`;
      TUIServer.TUICore.TUIServer.TUIConversation.getConversationProfile(name).then((imResponse: any) => {
        // 通知 TUIConversation 添加当前会话
        // Notify TUIConversation to toggle the current conversation
        TUIServer.TUICore.TUIServer.TUIConversation.handleCurrentConversation(imResponse.data.conversation);
      });
    };
    const checkFriend = async () => {
      if (!(data.userInfo as any).userID) return;
      const relation = await TUIServer.checkFriend((data.userInfo as any).userID, TIM.TYPES.SNS_CHECK_TYPE_BOTH);
      data.isFriendShip = relation === TIM.TYPES.SNS_TYPE_BOTH_WAY ? true : false;
    };

    const showEnter = () => {
      return data.isFriendShip || !TUIServer?.TUICore?.isOfficial;
    };
    return {
      ...toRefs(data),
      enter,
      showEnter,
    };
  },
});
export default memberProfile;
