import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
import { TUICallKit } from './components/TUICallKit/Web';
// import TUICallKit
// import { TUICallKit } from './TUIKit/TUIPlugin/TUICallKit';
import { TUICallKitServer } from './components/TUICallKit/Web';
// const nums =Math.floor(Math.random() * 10000)
const SDKAppID = 1400790838; // Your SDKAppID
// const secretKey = 'f9a46591828ec7c516186faff9f81e0b0e14128308664951ef1300fa0a8a711a'; //Your secretKey
// const userID = `service${nums}`; // User ID

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});

// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// TUIKit add TUICallKit
TUIKit.use(TUICallKit);

// login TUIKit
// TUIKit.login({
//   userID: userID,
//   userSig: genTestUserSig({
//     SDKAppID,
//     secretKey,
//     userID,
//   }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
// });
const app = createApp(App)
app.config.globalProperties.$basePath = 'https://www.baidu.com'
createApp(App).use(TUIKit).mount('#app');