import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cf28738"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-audio", [_ctx.data.message.flow === 'out' && 'reserve']]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.play && _ctx.play(...args)), ["stop"])),
    style: _normalizeStyle(`width: ${_ctx.data?.second * 10 + 40}px`)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["icon icon-voice", [_ctx.data.message.flow === 'out' && 'icon-reserve']])
    }, null, 2),
    _createElementVNode("label", null, _toDisplayString(_ctx.data.second) + "s", 1),
    _createElementVNode("audio", {
      ref: "audio",
      src: _ctx.data.url
    }, null, 8, _hoisted_1)
  ], 6))
}