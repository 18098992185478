import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LiteItem = _resolveComponent("LiteItem")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["TUI-conversation-list", [_ctx.isH5 ? 'list-h5' : '']]),
    ref: "list",
    onMousewheel: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollChange && _ctx.scrollChange(...args))),
    onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollChange && _ctx.scrollChange(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.list, (item, index) => {
      return (_openBlock(), _createBlock(_component_LiteItem, {
        isH5: _ctx.isH5,
        key: index,
        currentID: _ctx.currentID,
        conversation: item,
        toggleID: _ctx.toggleID,
        handleConversation: {
        avator: _ctx.data.handleItemAvator,
        name: _ctx.data.handleItemName,
        showAt: _ctx.data.handleShowAt,
        showMessage: _ctx.data.handleShowMessage,
        time: _ctx.data.handleItemTime,
        userID: _ctx.data.handleItemUserID,
      },
        displayOnlineStatus: _ctx.displayOnlineStatus,
        userStatusList: _ctx.userStatusList,
        onToggle: _ctx.handleToggleListItem,
        onOpen: _ctx.handleListItem,
        onHandle: _ctx.handleItem,
        types: _ctx.types
      }, null, 8, ["isH5", "currentID", "conversation", "toggleID", "handleConversation", "displayOnlineStatus", "userStatusList", "onToggle", "onOpen", "onHandle", "types"]))
    }), 128))
  ], 34))
}