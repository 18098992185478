
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      message: {},
    });

    watchEffect(() => {
      data.message = props.data;
    });

    return {
      ...toRefs(data),
    };
  },
});
