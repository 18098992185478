
import TUIAegis from '../../../../../utils/TUIAegis';
import { onClickOutside } from '@vueuse/core';
import { defineComponent, reactive, watchEffect, toRefs, ref } from 'vue';
import Link from '../../../../../utils/link';
import constant from '../../../constant';
import { handleOptions } from '../../utils/utils';

const Evaluate = defineComponent({
  type: 'custom',
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      link: 'https://web.sdk.qcloud.com/im/doc/zh-cn//SDK.html#createCustomMessage',
      list: 5,
      show: false,
      isMute: false,
      options: {
        data: {
          businessID: constant.typeEvaluate,
          version: 1,
          score: '',
          comment: '',
        },
        description: '对本次的服务评价',
        extension: '对本次的服务评价',
      },
      num: 0,
    });

    const dialog: any = ref();

    onClickOutside(dialog, () => {
      data.show = false;
    });

    watchEffect(() => {
      data.show = props.show;
      data.isMute = props.isMute;
    });

    const toggleShow = () => {
      data.show = !data.show;
      if (data.show) {
        data.options = {
          data: {
            ...handleOptions(constant.typeEvaluate, 1, { score: '', comment: '' }),
          },
          description: '对本次的服务评价',
          extension: '对本次的服务评价',
        };
        data.num = 0;
      }
    };

    const select = (item: any, index: number) => {
      data.num = index + 1;
      (data.options.data as any).score = `${data.num}`;
    };

    const submit = () => {
      Evaluate.TUIServer.sendCustomMessage(data.options);
      TUIAegis.getInstance().reportEvent({
        name: 'messageType',
        ext1: 'typeCustom',
      });
      toggleShow();
    };
    const openLink = (type: any) => {
      window.open(type.url);
      TUIAegis.getInstance().reportEvent({
        name: 'openLink',
        ext1: type.label,
      });
    };
    return {
      ...toRefs(data),
      toggleShow,
      select,
      submit,
      dialog,
      Link,
      openLink,
    };
  },
});
export default Evaluate;
