import { vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e16a46e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.isEdit)
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 0,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)), ["enter"]))
        }, null, 544)), [
          [_vModelText, _ctx.input]
        ])
      : (_openBlock(), _createElementBlock("section", _hoisted_2, [
          (!_ctx.groupProfile.notification)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(`TUIChat.manage.暂无公告`)), 1))
            : (_openBlock(), _createElementBlock("article", _hoisted_4, _toDisplayString(_ctx.groupProfile.notification), 1))
        ])),
    (_ctx.isAuth)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
          (_ctx.isEdit)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)))
              }, _toDisplayString(_ctx.$t(`TUIChat.manage.发布`)), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isEdit = !_ctx.isEdit))
              }, _toDisplayString(_ctx.$t(`TUIChat.manage.编辑`)), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}