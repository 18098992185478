
import { defineComponent, watchEffect, reactive, toRefs, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const manageName = defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  setup(props:any, ctx:any) {
    const data:any = reactive({
      groupProfile: {},
      input: '',
      isEdit: false,
    });

    watchEffect(() => {
      data.groupProfile = props.data;
    });

    const dialog:any = ref();


    onClickOutside(dialog, () => {
      data.isEdit = false;
    });

    const updateProfile = async () => {
      if (data.input && data.input !== data.groupProfile.name) {
        ctx.emit('update', { key: 'name', value: data.input });
        data.groupProfile.name = data.input;
        data.input = '';
      }
      toggleEdit();
    };

    const toggleEdit = async () => {
      if (props.isAuth) {
        data.isEdit = !data.isEdit;
      }
      if (data.isEdit) {
        data.input = data.groupProfile.name;
      }
    };

    return {
      ...toRefs(data),
      updateProfile,
      toggleEdit,
      dialog,
    };
  },
});
export default manageName;
