import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17431992"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "message-image",
  ref: "skeleton"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "message-img",
      src: _ctx.data.url
    }, null, 8, _hoisted_2)
  ], 512))
}