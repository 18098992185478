<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" class="design-iconfont">
        <rect width="40" height="40" rx="20" fill="#DADADA" fill-opacity=".3" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.6665 14.1667C16.6665 12.3284 18.1615 10.8334 19.9998 10.8334C21.8382 10.8334 23.3332 12.3284 23.3332 14.1667V19.1667C23.3332 21.005 21.8382 22.5 19.9998 22.5C18.1615 22.5 16.6665 21.005 16.6665 19.1667V14.1667ZM16.6665 28.3334C16.6665 27.8725 17.0398 27.5 17.4998 27.5H22.4998C22.9607 27.5 23.3332 27.8725 23.3332 28.3334C23.3332 28.7942 22.9607 29.1667 22.4998 29.1667H17.4998C17.0398 29.1667 16.6665 28.7942 16.6665 28.3334Z"
            fill="#fff" />
        <path
            d="M25.8332 18.3512V19.5083C25.8332 22.0811 23.2215 25 19.9998 25C16.7782 25 14.1665 22.0811 14.1665 19.5083V18.3334"
            stroke="#fff" stroke-width="1.66667" stroke-linecap="round" />
    </svg>
</template>