import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-084e755c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name" }
const _hoisted_2 = {
  key: 0,
  class: "edit-h5-header"
}
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "input-box" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "edit-h5-footer"
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "icon icon-edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t(`TUIChat.manage.群名称`)), 1),
    (_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.isH5 ? 'edit-h5' : '']),
          ref: "dialog"
        }, [
          _createElementVNode("main", null, [
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                  _createElementVNode("aside", _hoisted_3, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t(`TUIChat.manage.修改群聊名称`)), 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`TUIChat.manage.修改群聊名称后，将在群内通知其他成员`)), 1)
                  ]),
                  _createElementVNode("span", {
                    class: "close",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleEdit && _ctx.toggleEdit(...args)))
                  }, _toDisplayString(_ctx.$t(`关闭`)), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isEdit)
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    class: "input",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
                    type: "text",
                    onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)), ["enter"]))
                  }, null, 544)), [
                    [_vModelText, _ctx.input]
                  ])
                : _createCommentVNode("", true),
              (_ctx.isH5)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(`TUIChat.manage.仅限中文、字母、数字和下划线，2-20个字`)), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "btn",
                    disabled: !_ctx.input,
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateProfile && _ctx.updateProfile(...args)))
                  }, _toDisplayString(_ctx.$t(`确认`)), 9, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.isEdit || _ctx.isH5)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleEdit && _ctx.toggleEdit(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.groupProfile.name), 1),
          (_ctx.isAuth)
            ? (_openBlock(), _createElementBlock("i", _hoisted_8))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}