
import { defineComponent, watchEffect, reactive, toRefs } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {},
    });

    watchEffect(() => {
      data.data = props.data;
    });

    const download = () => {
      const file: any = data.data;
      const option: any = {
        mode: 'cors',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
      // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
      if ((window as any).fetch) {
        fetch(file.url, option)
          .then((res) => res.blob())
          .then((blob) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = file.name;
            a.click();
          });
      } else {
        const a = document.createElement('a');
        a.href = file.url;
        a.target = '_blank';
        a.download = file.name;
        a.click();
      }
    };

    return {
      ...toRefs(data),
      download,
    };
  },
});
