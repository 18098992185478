import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-087d9968"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "dialog-main-content" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["dialog", [_ctx.isH5 ? 'dialog-h5' : '', _ctx.center ? 'center' : '']]),
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleView && _ctx.toggleView(...args)), ["self"]))
      }, [
        _createElementVNode("main", {
          class: "dialog-main",
          style: _normalizeStyle(!_ctx.background && {'background': 'none'})
        }, [
          (_ctx.isHeaderShow)
            ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
                _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
                _createElementVNode("i", {
                  class: "icon icon-close",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleView && _ctx.toggleView(...args)))
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          (_ctx.isFooterShow)
            ? (_openBlock(), _createElementBlock("footer", _hoisted_3, [
                _createElementVNode("button", {
                  class: "btn btn-cancel",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleView && _ctx.toggleView(...args)))
                }, _toDisplayString(_ctx.$t('component.取消')), 1),
                _createElementVNode("button", {
                  class: "btn btn-default",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
                }, _toDisplayString(_ctx.$t('component.确定')), 1)
              ]))
            : _createCommentVNode("", true)
        ], 4)
      ], 2))
    : _createCommentVNode("", true)
}