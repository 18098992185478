import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51283954"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-header" }
const _hoisted_2 = { class: "profile-avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "profile-main" }
const _hoisted_5 = { class: "profile-main-item" }
const _hoisted_6 = { class: "profile-main-name" }
const _hoisted_7 = {
  key: 0,
  class: "gender"
}
const _hoisted_8 = { class: "profile-main-item" }
const _hoisted_9 = { class: "profile-main-label" }
const _hoisted_10 = {
  key: 0,
  class: "profile-main-item"
}
const _hoisted_11 = { class: "profile-main-label" }
const _hoisted_12 = {
  key: 0,
  class: "profile-main"
}
const _hoisted_13 = { class: "profile-main-item" }
const _hoisted_14 = { class: "profile-main-label" }
const _hoisted_15 = { class: "profile-main-item" }
const _hoisted_16 = { class: "profile-main-label" }
const _hoisted_17 = {
  key: 1,
  class: "icon icon-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TUIProfileEdit = _resolveComponent("TUIProfileEdit")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["TUI-profile", [_ctx.env.isH5 ? 'TUI-profile-h5' : '']])
  }, [
    (!_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "profile",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleEdit && _ctx.toggleEdit(...args)))
        }, [
          _createElementVNode("header", _hoisted_1, [
            _createElementVNode("aside", _hoisted_2, [
              _createElementVNode("img", {
                class: "avatar",
                src: _ctx.profile.avatar ? _ctx.profile.avatar : 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png',
                onerror: "this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("ul", _hoisted_4, [
              _createElementVNode("li", _hoisted_5, [
                _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.profile.nick || '-'), 1),
                (!_ctx.env.isH5)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.profile.gender ? _ctx.$t(`TUIProfile.${_ctx.genderLabel[_ctx.profile.gender]}`) : ''), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("li", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('TUIProfile.用户ID')) + ":", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.profile.userID), 1)
              ]),
              (_ctx.env.isH5)
                ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                    _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('TUIProfile.个性签名')) + ":", 1),
                    _createElementVNode("span", null, _toDisplayString(_ctx.profile.selfSignature || _ctx.$t('TUIProfile.暂未设置')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (!_ctx.env.isH5)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                _createElementVNode("li", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('TUIProfile.个性签名')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.profile.selfSignature || _ctx.$t('TUIProfile.暂未设置')), 1)
                ]),
                _createElementVNode("li", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('TUIProfile.出生年月')), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.profile.birthday ? _ctx.profile.birthday : _ctx.$t('TUIProfile.暂未设置')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.env.isH5)
            ? (_openBlock(), _createElementBlock("i", _hoisted_17))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_TUIProfileEdit, {
          key: 1,
          userInfo: _ctx.profile,
          isH5: _ctx.env.isH5,
          onSubmit: _ctx.submit,
          onCancel: _ctx.cancel
        }, null, 8, ["userInfo", "isH5", "onSubmit", "onCancel"]))
  ], 2))
}