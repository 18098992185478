import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dfa99d56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "words-main-content",
  ref: "dialog"
}
const _hoisted_2 = { class: "words-list" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["words", [_ctx.isH5 ? 'words-H5' : '']])
  }, [
    _createElementVNode("i", {
      class: "icon icon-words",
      title: "快速回复",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }),
    _withDirectives(_createElementVNode("main", {
      class: _normalizeClass(["words-main", [_ctx.isH5 ? 'words-H5-main' : '']])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", null, [
          _createElementVNode("aside", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('Words.常用语-快捷回复工具')), 1)
          ]),
          (_ctx.isH5)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "close",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
              }, "关闭"))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "words-list-item",
              key: index,
              onClick: ($event: any) => (_ctx.select(item))
            }, [
              _createElementVNode("label", null, _toDisplayString(item.value), 1)
            ], 8, _hoisted_3))
          }), 128))
        ])
      ], 512)
    ], 2), [
      [_vShow, _ctx.show&&!_ctx.isMute]
    ])
  ], 2))
}