import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43000c27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-back" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "header-close" }
const _hoisted_5 = { class: "body" }
const _hoisted_6 = { class: "body-message" }
const _hoisted_7 = { class: "body-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RepliesItem = _resolveComponent("RepliesItem")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["replies", [_ctx.isH5 ? 'replies-H5' : '', _ctx.isMenuOpen ? 'replies-menu-open' : '']]),
        ref: "dialog"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isH5)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "icon icon-back",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('TUIChat.回复详情')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isH5)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "icon icon-close",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_RepliesItem, {
              message: _ctx.message,
              isH5: _ctx.isH5,
              isRoot: true
            }, null, 8, ["message", "isH5"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.replies, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "body-list-item"
                }, [
                  _createVNode(_component_RepliesItem, {
                    message: item,
                    isH5: _ctx.isH5,
                    isRoot: false
                  }, null, 8, ["message", "isH5"])
                ]))
              }), 128))
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}